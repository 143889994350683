import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

export default function AccordionData({
  faqtext,
  showItems,
  setShowIndex,
  isBgBlack,
}) {
  const handleShow = () => {
    setShowIndex();
  };

  return (
    <div className="border-b border-dashed border-[#B8B8B8] py-5">
      <div
        onClick={handleShow}
        className={`${isBgBlack ? 'text-[#ffffff]' : 'text-[#303030]'} lg:text-[30px] md:text-[30px] text-[18px] lg:font-[700] font-[700] flex justify-between cursor-pointer`}
      >
        <span>{faqtext?.Text}</span>
        <span
          className={`border border-[#B8B8B8] ${isBgBlack ? 'bg-[transparent]' : 'bg-[#F2F5F6]'} w-[34px] h-[34px] rounded-[100%] flex items-center justify-center`}
        >
          <svg
            className="w-[10px] h-[10px]"
            viewBox="0 0 21 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.68725 0.272657C1.59178 0.182439 1.47947 0.11191 1.35674 0.065094C1.23401 0.0182781 1.10327 -0.00390625 0.971965 -0.000191689C0.840663 0.00352287 0.711378 0.0330629 0.591492 0.0867414C0.471606 0.14042 0.363467 0.217186 0.273249 0.312657C0.183031 0.408129 0.112501 0.520435 0.0656852 0.643164C0.0188697 0.765892 -0.00331434 0.89664 0.000400002 1.02794C0.00411435 1.15924 0.0336542 1.28853 0.0873329 1.40841C0.141012 1.5283 0.217778 1.63644 0.313249 1.72666L9.31325 10.2267C9.49892 10.4022 9.74474 10.5 10.0002 10.5C10.2558 10.5 10.5016 10.4022 10.6872 10.2267L19.6882 1.72666C19.7858 1.63704 19.8646 1.52892 19.92 1.40859C19.9754 1.28827 20.0064 1.15813 20.0111 1.02573C20.0158 0.893337 19.9942 0.761328 19.9474 0.637371C19.9007 0.513414 19.8298 0.399981 19.7388 0.30366C19.6479 0.20734 19.5387 0.130052 19.4176 0.0762854C19.2965 0.0225191 19.166 -0.00665283 19.0335 -0.0095377C18.9011 -0.0124226 18.7694 0.0110388 18.6461 0.0594826C18.5228 0.107926 18.4103 0.180387 18.3153 0.272657L10.0002 8.12466L1.68725 0.272657Z"
              fill="#F98600"
            />
          </svg>
        </span>
      </div>
      {showItems && (
        <div>
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            className={`${isBgBlack && 'text-[#ffffff]'}  font-[400] xl:text-[20px] md:text-[18px] text-[14px] title_hd leading-tight ${faqtext?.descriptionEditor && 'xl:mt-[24px] mt-[20px]'}`}
            source={faqtext?.descriptionEditor}
          >
            {faqtext?.descriptionEditor}
          </ReactMarkdown>
        </div>
      )}
    </div>
  );
}
